// component
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;



const navKasse = [
    {
      title: 'Übersicht',
      path: '/kasse',
      icon: <Iconify icon={'mdi:money'} color='#000' />,
    },
    {
      title: 'Backup',
      path: '/backup',
      icon: <Iconify icon={'mdi:backup'} color='#000' />,
    },
    {
      title: 'Corpsbrüder',
      path: '/users',
      icon: <Iconify icon={'mdi:people'} color='#000' />,
    },
    {
      title: 'Upload Excel',
      path: '/upload',
      icon: <Iconify icon={'mdi:upload'} color='#000' />,
    },
    {
      title: 'Logout',
      path: '/login',
      icon: <Iconify icon={'mdi:logout'} color='#000' />,
    }]

const navConfig = [
  {
    title: 'Home',
    path: '/dashboard/home',
    icon: <Iconify icon={'mdi:home'} color='#000' />,
  },
  {
    title: 'Drinks',
    path: '/dashboard/drinks',
    icon: <Iconify icon={'mdi:drink'} color='#000' />,
  },
  {
    title: 'Klausuren',
    path: '/dashboard/app',
    icon: <Iconify icon={'mdi:chart-bar'} color='#000' />,
  },
  
  {
    title: 'Neuer Bericht',
    path: '/dashboard/newpage',
    icon: <Iconify icon={'mdi:plus'} color='#000' />,
  },
  
  {
    title: 'Impressum',
    path: '/dashboard/imprint',
    icon: <Iconify icon={'mdi:business'} color='#333' />,
  },{
    title: 'Logout',
    path: '/login',
    icon: <Iconify icon={'mdi:logout'} color='#000' />,
  }
];

export { navConfig as default, navKasse };
