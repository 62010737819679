import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled  , alpha} from '@mui/material/styles';

import { Button, CircularProgress, Card,Typography, Container,  Box, Link, Drawer, Avatar, Stac } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';


import Iconify from '../components/iconify';
import NavSection from '../components/nav-section';
import './App.css';
import CONFIG from "../config";
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
 
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: "30px"
}));

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const TabNames = ["Fässer", "Bier", "fuchsenkiosk", "wasser/Pullen", "limo", "Wäsche"]
const BackColors = ["#144b74","#bc6c25", "#5b9c6b", "#669cb6", "#7c538f", "#bfa82c"]
const buttons = TabNames.map(() => React.createRef());

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));
export default function EditUsers() {
  const [user, setUser] = useState({
    firstName: '',
    secondName: '',
    email: '',
    birthdate: '',
    status: 'fuchs',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };
   
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('deltatoken');
      const response = await fetch(`${CONFIG.BACKEND_URL}/admin/add_user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        const result = await response.json();
        alert("Success");
      } else {
        alert("Failed to add user. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  
  const navConfig = [
    {
      title: 'Übersicht',
      path: '/kasse',
      icon: <Iconify icon={'mdi:money'} color='#000' />,
    },
    {
      title: 'Backup',
      path: '/backup',
      icon: <Iconify icon={'mdi:backup'} color='#000' />,
    },
    {
        title: 'Corpsbrüder',
        path: '/users',
        icon: <Iconify icon={'mdi:people'} color='#000' />,
    },
    {
      title: 'Upload Excel',
      path: '/upload',
      icon: <Iconify icon={'mdi:upload'} color='#000' />,
    },
    {
      title: 'Logout',
      path: '/login',
      icon: <Iconify icon={'mdi:logout'} color='#000' />,
    }]
  return (
    <>
      <Helmet>
        <title> Corpsbrüder </title>
      </Helmet>
    <div style={{width: "300px", position: "fixed", background: "#ebeef1", height: "100%", bottom: 0, top: 0, left: 0, zIndex: 300}}>
      <Box sx={{ mb: 5, mx: 2.5, marginTop: "20px" }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={"a"} alt={"Kasse"}/>

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                Kasse
              </Typography>

              
            </Box>
          </StyledAccount>
        </Link>
      </Box>
      <NavSection data={navConfig} />
</div>
      <Container style={{marginLeft: "300px"}}>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center', marginLeft: "0px" }}>


<form onSubmit={handleSubmit}>
  <h2>Corpsbruder hinzufügen</h2>
  <div>
    <label htmlFor="firstName">Vorname</label>
    <input
      type="text"
      id="firstName"
      name="firstName"
      value={user.firstName}
      onChange={handleChange}
      required
    />
  </div>

  <div>
    <label htmlFor="secondName">Nachname</label>
    <input
      type="text"
      id="secondName"
      name="secondName"
      value={user.secondName}
      onChange={handleChange}
      required
    />
  </div>

  <div>
    <label htmlFor="email">Email</label>
    <input
      type="email"
      id="email"
      name="email"
      value={user.email}
      onChange={handleChange}
      required
    />
  </div>

  <div>
    <label htmlFor="birthdate">Geburtstag</label>
    <input
      type="date"
      id="birthdate"
      name="birthdate"
      value={user.birthdate}
      onChange={handleChange}
    />
  </div>

  <div>
    <label htmlFor="status">Status:</label>
    <select
      id="status"
      name="status"
      value={user.status}
      onChange={handleChange}
      required
    >
      <option value="ah">AH</option>
      <option value="iacb">iaCB</option>
      <option value="cb">CB</option>
      <option value="fuchs">Fuchs</option>
    </select>
  </div>

  <button type="submit">Hinzufügen</button>
</form>
          
        </StyledContent> 
      </Container>
    </>
  );
}
