
import { Helmet } from 'react-helmet-async';

import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState ,useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Slider,
  Alert,
  Snackbar,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  Autocomplete,
  TableRow,
  Grid,
  Pagination,
  Radio ,
  TextField,
  MenuItem,
  Chip,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CardHeader,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';


import {
  Upload
} from '../sections/@dashboard/app';


import CONFIG from "../config";

export default function NewReport() {
  
  useEffect(() => {
    const token = localStorage.getItem('deltatoken');
    if (!token) {
      window.location.href = '/login';
      return;
    }
    fetch(`${CONFIG.BACKEND_URL}/protected`, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` }
    }).then(response => {
      console.log(response)
    })
    .catch(error => {
      console.log(error);
      window.location.href = '/login';
    })
  }, []);
  
  
  const [currTitle, setCurrTitle] = useState("");
  const [study, setStudy] = useState("");
  const [semester, setSemester] = useState(localStorage.getItem('deltaSemester'));
  const [institut, setInstitut] = useState();
  const [semesterVerlauf, setSemesterVerlauf] = useState();
  const [cp, setCP] = useState();
  const [note, setNote] = useState();
  const [versuch, setVersuch] = useState();
  const [bewertungLernzeit, setBewertungLernzeit] = useState();
  const [klausurRueckblick, setKlausurRueckblick] = useState();
  const [anzahlAltklausuren, setAnzahlAltklausuren] = useState();
  const [anzahlLerntage, setAnzahlLerntage] = useState();
  const [stundenLerntag, setStundenLerntag] = useState();
  const [tipps, setTipps] = useState();
  const [toUpload, setToUpload] = useState([]);
  const [tippsAufwand, setTippsAufwand] = useState();
  const [tippsUebungen, setTippsUebungen] = useState();
  const [tippsVorlesung, setTippsVorlesung] = useState();
  const [jahr, setJahr] = useState((new Date()).getFullYear());
  const [name, setName] = useState(decodeURIComponent(window.location.hash.substring(1)));

  
  const edit = {
      width: "100%",
      lineHeight: 1.4,
      backgroundColor: "#f9f9f9",
      border: "1px solid #e5e5e5",
      borderRadius: "7px",
      transition: "all 0.35s ease-in-out",
      marginBottom: "10px",
  }
  const edit2 = {
      width: "33.33%",
      lineHeight: 1.4,
      backgroundColor: "#f9f9f9",
      border: "1px solid #e5e5e5",
      borderRadius: "7px",
      transition: "all 0.35s ease-in-out",
      marginBottom: "10px",
      float: "left"
  }
  async function postData(url, requestBody) {
    const token = localStorage.getItem('token');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody),
    });
    const data = await response.json();
    return data;
  }
  async function fetchData(url) {
    const token = localStorage.getItem('deltatoken');
    console.log(token)
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    const data = await response.json();
    return data;    
  }

  const [options, setOptions] = useState(["test", "tes2"]);
  const [options2, setOptions2] = useState(["t"]);
  const [names, setNames] = useState(["t"]);
  const [person, setPerson] = useState(localStorage.getItem('deltaPerson'));
  const [subject, setSubject] = useState(localStorage.getItem('deltaSubject'));
  const [subjects, setSubjects] = useState([""]);

  useEffect(() => {  
    async function fetchUsers() {
      const  profiles  = await fetchData(`${CONFIG.BACKEND_URL}/get_reports`);
      const reps = JSON.parse(profiles);
      console.log(profiles)
      setOptions(reps.map((item) => item.klausurname));
    }
    fetchUsers();
  }, []);
  useEffect(() => {  
    async function fetchUsers() {
      const  profiles  = await fetchData(`${CONFIG.BACKEND_URL}/get_institus`);
      const reps = JSON.parse(profiles);
      console.log(profiles)
      setOptions2(reps.map((item) => item.institut));
    }
    fetchUsers();
  }, []);
  useEffect(() => {  
    async function fetchUsers() {
      const  profiles  = await fetchData(`${CONFIG.BACKEND_URL}/get_names`);
      const reps = JSON.parse(profiles);
      console.log(profiles)
      setNames(reps.map((item) => item.name));
    }
    fetchUsers();
  }, []);
  useEffect(() => {  
    async function fetchUsers() {
      const  profiles  = await fetchData(`${CONFIG.BACKEND_URL}/get_subjects`);
      const reps = JSON.parse(profiles);
      console.log(profiles)
      setSubjects(reps.map((item) => item.studiengang));
    }
    fetchUsers();
  }, []);
  const filterOptions = (options, { inputValue }) => {
    console.log(options)
    const filteredOptions = options.filter((option) =>
      option.toUpperCase().includes(inputValue.toUpperCase())
    );
    
    const sortedOptions = filteredOptions.sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0; 
    });
    return sortedOptions.slice(0, 5);
  };
  
  
  const [selectedOption, setSelectedOption] = useState('a');
  const [page, setPage] = useState(1);
  const [einsicht, setEinsicht] = useState(false);
  const [zulassung, setZulassung] = useState(false);
  const [auswendig, setAuswendig] = useState(false);
  const [formelsammlung, setFormelsammlung] = useState(false);
  const [graduation, setGraduation] = useState(localStorage.getItem('deltaGraduation') || "Bachelor");
  const [uni, setUni] = useState(localStorage.getItem('deltaUni') || "RWTH");
  const [aufwandVal, setAufwandVal] = useState(50);
  const [stundenWoche, setStundenWoche] = useState();
  const [hilfreicheVeranstaltungen, setHilfreicheVeranstaltungen] = useState();
  const [sws, setSWS] = useState(localStorage.getItem('deltaSWS') || "SS");
  const [mehrwertVorlesungen, setMehrwertVorlesungen] = useState(50);
  const [mehrwertUebungen, setMehrwertUebungen] = useState(50);
  const [uploadResult, setUploadResult] = useState(null);

  const handleUploadResult = (status) => {
    setUploadResult(status);
  };

  const handleSnackbarClose = () => {
    setUploadResult(null);
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 50); 
  };

  const saveReport = () => {
    const missingVals = [];
    if(person === undefined || person === null ||  person.length === 0){
      missingVals.push("Dein_Name")
    }
    if(subject === undefined || subject === null ||subject.length === 0){
      missingVals.push("Studiengang")
    }
    if(name === undefined || name === null || name.length === 0){
      missingVals.push("Klausurname")
    }
    if(tipps === undefined|| tipps === null  || tipps.length === 0){
      missingVals.push("Tipps")
    }
    if(anzahlLerntage === undefined || anzahlLerntage === null  ||anzahlLerntage.length === 0 ||anzahlLerntage === 0){
      missingVals.push("Anzahl Lerntage")
    }
    if (missingVals.length > 0){
      setPage(1);
      alert(`Folgendende angaben fehlen:\n${missingVals.join('\n')}`);
      return missingVals;
    }
    const total = {
      "username2": person,
      "studying": (subject || ""),
      "semester": (semester || 0),
      "graduation": graduation, 
      "uni": uni, 
      "klausurname": (name || ""),
      "institut": (institut || ""),
      "semesterklausur": (semesterVerlauf || 0),
      "note": (note || 0),
      "versuch": (versuch || 0),
      "cp": (cp || 0),
      "einsicht": einsicht,
      "zulassung": zulassung,
      "wochenstunden": (stundenWoche || 0),
      "hilfreiche_veranstaltungen":  (hilfreicheVeranstaltungen || ""),
      "aufwand_val": (aufwandVal || ""),
      "aufwand_tipps": (tippsAufwand || ""),
      "mehrwert_vorlesungen": (mehrwertVorlesungen || ""),
      "vorlesungen_tipps":  (tippsVorlesung || ""),
      "mehrwert_uebungen": mehrwertUebungen,
      "uebungen_tipps": (tippsUebungen || ""),
      "anzahllerntage": (anzahlLerntage || 0),
      "stunden_lerntag": (stundenLerntag || 0),
      "bewertung_lernzeit": (bewertungLernzeit || ""),
      "anzahlaltklausuren": (anzahlAltklausuren || 0),
      "tipps": (tipps || ""),
      "klausur_rueckblick": (klausurRueckblick || ""),
      "auswendig": auswendig,
      "formelsammlung": formelsammlung,
      "test":  "",
      "jahr": jahr,
      "sws": sws //   TODO
    }     
    console.log( Object.values(total))
    console.log(total)
    postData(`${CONFIG.BACKEND_URL}/save_report`, {input:  Object.values(total)})
    setUploadResult('success')
    return [];
  }

  return (
    <>
      <Helmet>
        <title> Delta Studies </title>
      </Helmet>


      <Container style={{maxWidth: '800px'}}>
        {page === 1 && (<Card >
          <CardHeader title={"Delta Klausur-Erfahrungsbericht"} style={{textAlign: 'center'}}/>
          <div style={{padding: '25px', marginTop: '-10px'}}>
          <p style={{color: "#2E4053", textAlign: "center"}}>Deine Angaben</p>
             <Autocomplete
                    options={names}
                    freeSolo
                    onInputChange={(event, newValue) => {setPerson(newValue); localStorage.setItem('deltaPerson', newValue)}}
                    value={person}
                    filterOptions={filterOptions}
                    style={{marginBottom: '10px'}}
                    renderInput={(params) => <TextField {...params} style={edit} label="Dein Name" placeholder="Dein Name"/>}
                  />
             <Autocomplete
                    options={subjects}
                    freeSolo
                    value={subject}
                    onInputChange={(event, newValue) => {setSubject(newValue); localStorage.setItem('deltaSubject', newValue)}}
                    filterOptions={filterOptions}
                    style={{marginBottom: '10px'}}
                    renderInput={(params) => <TextField {...params} style={edit} label="Studiengang" placeholder="Studiengang"/>}
                  />
                  
              <TextField
                    value={semester}
                    fullWidth
                    style={edit}
                    onChange={(event) => {
                        const inputValue = event.target.value;
                        const sanitizedValue = inputValue.replace(/\D/g, '');
                        setSemester(sanitizedValue);               
                        localStorage.setItem('deltaSemester', sanitizedValue)       
                    }}     
                    placeholder='Aktuelles Semester'
                    label='Aktuelles Semester'
                    /> 
                    <div style={{float: "left", marginRight: "10px ",paddingTop: "5px"}}>            
                    <FormControlLabel
                      value="SS"
                      control={<Radio style={{ color: sws === 'SS' ? '#00aaff' : '#888' }} />}
                      label="SS"
                      checked={sws === 'SS'}
                      onChange={(event) => {setSWS(event.target.value); localStorage.setItem('deltaSWS', event.target.value)}}
                    />
                    <FormControlLabel
                      value="WS"
                      control={<Radio style={{ color: sws === 'WS' ? '#00aaff' : '#888' }} />}
                      label="WS"
                      checked={sws === 'WS'}
                      onChange={(event) => {setSWS(event.target.value); localStorage.setItem('deltaSWS', event.target.value)}}
                    /></div> 
                    <TextField
                          value={jahr}
                          onChange={(e) => setJahr(e.target.value)}
                          fullWidth
                          style={edit2}
                          placeholder='Jahr'
                          label='Jahr'
                          type="number" />  

                          <div style={{paddingTop: "80px", width: "100%", paddingBottom: "50px"}}>
                          <div style={{float: "left",paddingTop: "5px"}}>            
                    <FormControlLabel
                      value="RWTH"
                      control={<Radio style={{ color: uni === 'RWTH' ? '#00aaff' : '#888' }} />}
                      label="RWTH"
                      checked={uni === 'RWTH'}
                      onChange={(event) => {setUni(event.target.value); localStorage.setItem('deltaUni', event.target.value)}}
                    />
                    <FormControlLabel
                      value="FH"
                      control={<Radio style={{ color: uni === 'FH' ? '#00aaff' : '#888' }} />}
                      label="FH"
                      checked={uni === 'FH'}
                      onChange={(event) => {setUni(event.target.value); localStorage.setItem('deltaUni', event.target.value)}}
                    /></div> <div style={{paddingTop: "5px", float: "right"}}>            
                    <FormControlLabel
                      value="Bachelor"
                      control={<Radio style={{ color: graduation === 'Bachelor' ? '#00aaff' : '#888' }} />}
                      label="Bachelor"
                      checked={graduation === 'Bachelor'}
                      onChange={(event) => {setGraduation(event.target.value); localStorage.setItem('deltaGraduation', event.target.value)}}
                    />
                    <FormControlLabel
                      value="Master"
                      control={<Radio style={{ color: graduation === 'Master' ? '#00aaff' : '#888' }} />}
                      label="Master"
                      checked={graduation === 'Master'}
                      onChange={(event) => {setGraduation(event.target.value); localStorage.setItem('deltaGraduation', event.target.value)}}
                    /></div> </div> 
          </div>
          </Card>)}
          {page===2 && (<>
          <Card style={{marginTop: "10px"}}>
          <div style={{padding: '25px', marginTop: '-10px'}}>

          <p style={{color: "#2E4053", textAlign: "center"}}>Klausur</p>
          <Autocomplete
                    id="number-input"
                    options={options}
                    freeSolo
                    onInputChange={(event, newValue) => setName(newValue)}
                    filterOptions={filterOptions}
                    value={name}
                    style={{marginBottom: '10px'}}
                    renderInput={(params) => <TextField {...params} style={edit} value={name} label="Klausurname (bitte keine Abkürzung)" placeholder="Klausurname"/>}
                    />
                  
          <Autocomplete
                    id="number-input"
                    options={options2}
                    freeSolo
                    onInputChange={(event, newValue) => setInstitut(newValue)}
                    value={institut}
                    filterOptions={filterOptions}
                    style={{marginBottom: '10px'}}
                    renderInput={(params) => <TextField {...params} style={edit} label="Insitut/Professor" placeholder="Insitut/Professor"/>}
                   />
                  
                  
          <TextField
                    value={semesterVerlauf}
                    onChange={(event) => {
                        const inputValue = event.target.value;
                        const sanitizedValue = inputValue.replace(/\D/g, '');
                        setSemesterVerlauf(sanitizedValue);                      
                    }}
                    fullWidth
                    style={edit}
                    pattern="[0-9]*"  
                    inputMode="numeric"
                    placeholder='Semester im Studienverlaufsplan'
                    label='Semester im Studienverlaufsplan'  />
          <TextField
                    value={cp}
                    onChange={(event) => {
                        const inputValue = event.target.value;
                        
                        const sanitizedValueA = inputValue.replace(/,/g, '.').replace(/[^\d.]/g, '');
                        const sanitizedValue = sanitizedValueA.replace(/(\..*)\./g, '$1');
                        setCP(sanitizedValue);                      
                    }}
                    pattern="[0-9]*"  
                    inputMode="numeric"
                    fullWidth
                    style={edit2}
                    placeholder='Anzahl CP'
                    label='Anzahl CP' />
          <TextField
                    value={note}
                    onChange={(event) => {
                        const inputValue = event.target.value;
                        const sanitizedValueA =inputValue.replace(/,/g, '.').replace(/[^\d.]/g, '');
                        const sanitizedValue = sanitizedValueA.replace(/(\..*)\./g, '$1');
                        setNote(sanitizedValue);    
                    }}                        
                    style={edit2}
                    placeholder='Note'
                    label='Note'
                    />
          <TextField
                    value={versuch}
                    onChange={(event) => {
                        const inputValue = event.target.value;
                        const sanitizedValue = inputValue.replace(/\D/g, '');
                        setVersuch(sanitizedValue);                      
                    }}
                    pattern="[0-9]*"  
                    inputMode="numeric"
                    fullWidth
                    style={edit2}
                    placeholder='Versuch'
                    label='Versuch'
                    />
                              

          <div style={{paddingTop: "5px", float: "left"}}>
          <p style={{color: "#2E4053"}}>Einsicht besucht</p>            
                    <FormControlLabel
                      value="Ja"
                      control={<Radio style={{ color: einsicht ? '#00aaff' : '#888' }} />}
                      label="Ja"
                      checked={einsicht}
                      onChange={(event) => setEinsicht(true)}
                    />
                    <FormControlLabel
                      value="Nein"
                      control={<Radio style={{ color: !einsicht ? '#00aaff' : '#888' }} />}
                      label="Nein"
                      checked={!einsicht}
                      onChange={(event) => setEinsicht(false)}
                    /></div>    

          <div style={{paddingTop: "5px", float: "right"}}>
          <p style={{color: "#2E4053"}}>Klausurzulassung</p>            
                  <FormControlLabel
                      value="Ja"
                      control={<Radio style={{ color: zulassung ? '#00aaff' : '#888' }} />}
                      label="Ja"
                      checked={zulassung}
                      onChange={(event) => setZulassung(true)}
                    />
                    <FormControlLabel
                      value="Nein"
                      control={<Radio style={{ color: !zulassung ? '#00aaff' : '#888' }} />}
                      label="Nein"
                      checked={!zulassung}
                      onChange={(event) => setZulassung(false)}
                    /></div>
          </div>
          </Card><Card style={{marginTop: "10px"}}>
          <div style={{padding: '25px', marginTop: '-10px'}}>

          <p style={{color: "#2E4053", textAlign: "center"}}>Im Semester</p>
          <Card style={{padding: "10px", margin: "5px"}}>
          <TextField
                      value={stundenWoche}
                      onChange={(event) => {
                          const inputValue = event.target.value;
                          const sanitizedValueA = inputValue.replace(/,/g, '.').replace(/[^\d.]/g, '');
                          const sanitizedValue = sanitizedValueA.replace(/(\..*)\./g, '$1');
                          setStundenWoche(sanitizedValue);                      
                      }}
                      fullWidth
                      multiline
                      style={edit}
                      placeholder='Stunden/Woche'
                      label='Stunden/Woche'  />
          <TextField
                      value={hilfreicheVeranstaltungen}
                      onChange={(e) => setHilfreicheVeranstaltungen(e.target.value)}
                      fullWidth
                      multiline
                      style={edit}
                      placeholder='Weitere hilfreiche Veranstaltungen'
                      label='Weitere hilfreiche Veranstaltungen'  />
            </Card>
          <Card style={{padding: "10px", margin: "5px"}}>
          <p>Aufwand</p>
          <Slider min={0} step={1} max={100}  value={aufwandVal}  onChange={(event) => setAufwandVal(event.target.value)}/>
          <p style={{ textAlign: "left"}}>Niedrig</p>
          <p style={{ textAlign: "right", marginTop: "-40px"}}>Hoch</p>

            <TextField
                      value={tippsAufwand}
                      onChange={(e) => setTippsAufwand(e.target.value)}
                      fullWidth
                      multiline
                      style={edit}
                      placeholder='Tipps'
                      label='Tipps'  />
            
                      
                      
                      </Card>
                      <Card style={{padding: "10px", margin: "5px"}}>
          <p>Mehrwert Vorlesungen</p>
          <Slider min={0} step={1} max={100}  value={mehrwertVorlesungen}  onChange={(event) => setMehrwertVorlesungen(event.target.value)} />
          <p style={{ textAlign: "left"}}>Niedrig</p>
          <p style={{ textAlign: "right", marginTop: "-40px"}}>Hoch</p>
            <TextField
                      value={tippsVorlesung}
                      onChange={(e) => setTippsVorlesung(e.target.value)}
                      fullWidth
                      multiline
                      style={edit}
                      placeholder='Tipps'
                      label='Tipps'  /></Card>
                      <Card style={{padding: "10px", margin: "5px"}}>
          <p>Mehrwert Übungen</p>
          <Slider min={0} step={1} max={100} value={mehrwertUebungen}  onChange={(event) => setMehrwertUebungen(event.target.value)}   />
                      <p style={{ textAlign: "left"}}>Niedrig</p>
                      <p style={{ textAlign: "right", marginTop: "-40px"}}>Hoch</p>

          <TextField
                      value={tippsUebungen}
                      onChange={(e) => setTippsUebungen(e.target.value)}
                      fullWidth
                      multiline
                      style={edit}
                      placeholder='Tipps'
                      label='Tipps'  />
                      
                      </Card>
          </div>
          </Card></>)}
          {page===3 && (<>
          <Card style={{marginTop: "10px"}}>
          <div style={{padding: '25px', marginTop: '-10px'}}>

          <p style={{color: "#2E4053", textAlign: "center"}}>In der Klausurphase</p>
          
          <TextField
                    value={anzahlLerntage}
                    onChange={(event) => {
                        const inputValue = event.target.value;
                        
                        const sanitizedValueA =inputValue.replace(/,/g, '.').replace(/[^\d.]/g, '');
                        const sanitizedValue = sanitizedValueA.replace(/(\..*)\./g, '$1');
                        setAnzahlLerntage(sanitizedValue);                      
                    }}
                    style={edit2}
                    placeholder='Anzahl Lerntage'
                    label='Anzahl Lerntage'
                    />
                    
          <TextField
                    value={stundenLerntag}
                    onChange={(event) => {
                        const inputValue = event.target.value;
                        const sanitizedValueA = inputValue.replace(/,/g, '.').replace(/[^\d.]/g, '');
                        const sanitizedValue = sanitizedValueA.replace(/(\..*)\./g, '$1');
                        setStundenLerntag(sanitizedValue);                      
                    }}                
                    style={edit2}
                    placeholder='Stunden/Lerntage'
                    label='Stunden/Lerntage'
                    />
          <TextField
                    value={anzahlAltklausuren}
                    onChange={(event) => {
                        const inputValue = event.target.value;
                        const sanitizedValue = inputValue.replace(/\D/g, '');
                        setAnzahlAltklausuren(sanitizedValue);                      
                    }}                  
                    style={edit2}
                    placeholder='Anzahl Altklausuren'
                    label='Anzahl Altklausuren'
                    />
          <TextField
                    value={bewertungLernzeit}
                    onChange={(e) => setBewertungLernzeit(e.target.value)}                    
                    style={edit}
                    placeholder='Bewertung der Lernzeit'
                    label='Bewertung der Lernzeit'
                    />
          <TextField
                    value={klausurRueckblick}
                    onChange={(e) => setKlausurRueckblick(e.target.value)}                    
                    style={edit}
                    lineHeight={5}
                    minRows={5}
                    multiline
                    placeholder='Klausur-Rückblick in ein paar Stichpunkten'
                    label='Klausur-Rückblick'
                    />
          <TextField
                    value={tipps}
                    onChange={(e) => setTipps(e.target.value)}                    
                    style={edit}
                    minRows={5}
                    multiline
                    placeholder='Tipps'
                    label='Tipps'
                    />

<div style={{paddingTop: "5px", float: "left"}}>
          <p style={{color: "#2E4053"}}>Auswendig lernen</p>            
          <FormControlLabel
                      value="Ja"
                      control={<Radio style={{ color: auswendig ? '#00aaff' : '#888' }} />}
                      label="Ja"
                      checked={auswendig}
                      onChange={(event) => setAuswendig(true)}
                    />
                    <FormControlLabel
                      value="Nein"
                      control={<Radio style={{ color: !auswendig ? '#00aaff' : '#888' }} />}
                      label="Nein"
                      checked={!auswendig}
                      onChange={(event) => setAuswendig(false)}
                    /></div>    

          <div style={{paddingTop: "5px", float: "right"}}>
          <p style={{color: "#2E4053"}}>Formelsammlung erlaubt</p>            
          <FormControlLabel
                      value="Ja"
                      control={<Radio style={{ color: formelsammlung ? '#00aaff' : '#888' }} />}
                      label="Ja"
                      checked={formelsammlung}
                      onChange={(event) => setFormelsammlung(true)}
                    />
                    <FormControlLabel
                      value="Nein"
                      control={<Radio style={{ color: !formelsammlung ? '#00aaff' : '#888' }} />}
                      label="Nein"
                      checked={!formelsammlung}
                      onChange={(event) => setFormelsammlung(false)}
                    /></div>
          </div>
          </Card>
          </>)}
          {page===4 && (<>
          <Card style={{marginTop: "10px"}}>
          <div style={{padding: '25px', marginTop: '0px'}}>
          <p style={{color: "#2E4053"}}>Lerndokumente</p>
          </div>
          <div style={{ padding: '20px', marginTop: "-20px"}}>
          
          <Upload _examName={name} setSelectedFiles={setToUpload} selectedFiles={toUpload}  handleUploadResult={handleUploadResult} saveAllButton saveReport={saveReport}/>
         
          </div>
          </Card></>)}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
          
          {page > 1 && (<Button variant='contained' style={{background: '#303030'}} onClick={() => {setPage(page -1)}}>Zurück</Button>)}
          <Pagination
              color="primary"
              shape="rounded"
              count={4}
              page={page}
              style={{ margin: '0 auto' }}
              onChange={handleChangePage}
            />
            {page < 4 && (<Button variant='contained' onClick={() => {setPage(page +1)}}>Weiter</Button>)}
            </div>
      </Container>

      <Snackbar open={uploadResult !== null} autoHideDuration={3000} onClose={handleSnackbarClose}>
          {uploadResult !== null && (<Alert onClose={handleSnackbarClose} severity={(uploadResult === 'success' || uploadResult===true ) ? 'success' : 'error'}>
            {(uploadResult === 'success' || uploadResult===true ) ? 'Files uploaded successfully!' : 'Failed to upload files.'}
          </Alert>)}
      </Snackbar>
    </>
  );
}
