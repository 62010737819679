import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled  } from '@mui/material/styles';

import { Button, Dialog, DialogTitle, Grid, CardHeader, Avatar, Card,Typography, Container, Box } from '@mui/material';
import { useState ,useEffect, useRef  } from 'react';
import CONFIG from "../config";
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
 
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: "30px"
}));

// ----------------------------------------------------------------------

const TriangleImage = styled('img')({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',  // Equilateral triangle points upwards
});
const TriangleContainer = styled('div')({
  position: "relative",
  width: "600px",
  height: "400px",
  marginTop: "50px"
});
export default function Home() {
  
  useEffect(() => {
    const token = localStorage.getItem('deltatoken');
    if (!token) {
      window.location.href = '/login';
      return;
    }
    fetch(`${CONFIG.BACKEND_URL}/protected`, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` }
    }).then(response => {
      console.log(response)
    })
    .catch(error => {
      console.log(error);
      if(localStorage.getItem('deltaUsernameDigital') !== "CC" && localStorage.getItem('deltaUsernameDigital') !== "Kasse"){
        window.location.href = '/login';
      }
    })
    if(localStorage.getItem('deltaUsernameDigital') === "Kasse"){
      window.location.href = '/kasse';
    }
  }, []);

  async function fetchImageData(url) {
    const token = localStorage.getItem('deltatoken');
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      },
    });
    const blob = await response.blob();
    return blob;
  }
  
  useEffect(() => {
    const fetchImg = async () => {
      
      if ((new Date()).getMonth() + 1 === 12 && (new Date()).getDate() <= 24 && localStorage.getItem("displayedDay") < (new Date()).getDate() ){
        setDialogOpen(true)
      
        const today = (new Date()).getDate();
        const imageUrl = `${CONFIG.BACKEND_URL}/images/${today}.jpeg`
          
          fetchImageData(imageUrl)
          .then(blob => {
            const imageUrl = URL.createObjectURL(blob);
            if (imageRef.current) {
              imageRef.current.src = imageUrl;
            }
          })
          .catch(error => {
            console.error('Error fetching image:', error);
            imageRef.current.src = "";
          });
          localStorage.setItem("displayedDay", (new Date()).getDate());
      }
    }
    fetchImg();
  }, []);

  const handleClose = () => {
    setDialogOpen(false);
  };
  const items = ["Drinks", "Paukplaner", "Studienerfahrungsberichte"];
  const links  = ["/dashboard/drinks", `https://paukkeller.de/direct_login/${localStorage.getItem('deltatoken')}`, "/dashboard/app"];
  const images = ["bier", "fencing", "study"];
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const imageRef =  useRef(null);
  return (
    <>
      <Helmet>
        <title> Delta Digital </title>
      </Helmet>

      <Dialog open={dialogOpen} onClose={handleClose}>
      <div style={{ background: '#222', color:'#fff', minWidth: "330px"}}>
       <DialogTitle style={{color: '#fff', fontSize: 35}}>{`Adventskalender ${(new Date()).getDate()}.${(new Date()).getMonth()+1}.`}</DialogTitle>
       <img ref={imageRef}    style={{height: '100%', maxHeight: "500px", width: '100%', objectFit:'cover',  transition: 'opacity 0.5s ease', background: '#999'}}  alt=""/>
        </div>
      </Dialog>
    
      <Typography variant="h3" paragraph style={{textAlign: "center"}}>
           Delta Digital
          </Typography>
      <Container>
      <Grid container spacing={2} >      
      {items.map((item, index) => {
              const isHovered = index === hoveredIndex;

              const cardStyle = {
                height: '180px',
                cursor: 'pointer',
                backgroundColor: isHovered ? '#ccc' : '#e1e4e9', 
              };

              return (
                <Grid item xs={12} md={6} lg={4} key={item.id}>
                    <a href={links[index]}>
                  <Card
                    style={cardStyle}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    onClick={() => {
                      setTimeout(() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                      }, 50); 
                    }}
                  >
                    <CardHeader title={item} style={{ textAlign: 'center' }} />
                    <Avatar sx={{ position: 'relative' , width: '100px',
                        height: '100px',
                        background: '#fff0',
                        margin: '0 auto',
                        marginBottom: '10px',}}>
                            <img
                              style={{ objectFit: 'cover', zIndex: 5 , padding: '10px'}}
                              src={`/assets/${images[index]}.png`}
                              alt="T"
                            />
                            
                          </Avatar>
                  </Card></a>
                </Grid>
              );
            })}
            </Grid>
      </Container>
      
      <StyledContent sx={{ textAlign: 'center', alignItems: 'center' ,marginTop: "0px"}}>
            <TriangleContainer>
      <TriangleImage src={"/assets/delta-background.jpeg"} alt="Triangle" /></TriangleContainer>        </StyledContent>

    </>
  );
}
