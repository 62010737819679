import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled  , alpha} from '@mui/material/styles';

import { Button, CircularProgress, Card,Typography, Container,  Box, Link, Drawer, Avatar, Stac } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';


import Iconify from '../components/iconify';
import NavSection from '../components/nav-section';
import { navKasse } from '../layouts/dashboard/nav/config'

import CONFIG from "../config";
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
 
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: "30px"
}));

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const TabNames = ["Fässer", "Bier", "fuchsenkiosk", "wasser/Pullen", "limo", "Wäsche"]
const BackColors = ["#144b74","#bc6c25", "#5b9c6b", "#669cb6", "#7c538f", "#bfa82c"]
const buttons = TabNames.map(() => React.createRef());

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));
export default function Kasse() {
    useEffect(() => {
        const token = localStorage.getItem('deltatoken');
        if (!token) {
          window.location.href = '/login';
          return;
        }
        fetch(`${CONFIG.BACKEND_URL}/protected`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${token}` }
        }).then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log(error);
          window.location.href = '/login';
        })    
      }, []);

  const [downloading, setDownloading] = useState(false);
  const [faesser, setFaesser] = useState({ faesser: [] });
  const [keys, setKeys] = useState([]);
  const [bier, setBier] = useState([]);
  const [table, setTable] = useState([]);
  
  
  const [showOnlyActive, setShowOnlyActive] = useState(true);
  const [deletingAction, setDeletingAction] = useState(false);
  const [currTabIndex, setCurrTabIndex] = useState(0);
  const [currColor, setCurrColor] = useState("#bc6c25");

  const downloadCsv = async () => {
    const token = localStorage.getItem('deltatoken');
  
    setDownloading(true)
    const response = await fetch(`${CONFIG.BACKEND_URL}/admin/excel`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const data = await response.json();
    
    if (response.ok) {
      if (data.notMatched.length > 0)
        alert(`ERROR! Folgende Corpsbrüder wurden nicht gemacht: ${data.notMatched}`);
      
      const fileUrl = `${CONFIG.BACKEND_URL}/kassenexl/${data.path}`;

      fetch(fileUrl)
        .then(response => response.blob())
        .then(blob => {
          // Generate a filename with the current date and time
          const now = new Date();
          const timestamp = now.toISOString()
            .replace(/[:.]/g, '-')
            .replace('T', '_');
          const filename = `strichliste_${timestamp}.xlsx`;
          // Create a downloadable link
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch(error => console.error('Error downloading file:', error));

    } else {
      console.error('Failed to download CSV file');
    }
    setDownloading(false)
  
  };

  
  async function fetchData(url) {
    const token = localStorage.getItem('deltatoken');
    console.log(token)
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    if(response.status === 401){
        window.location.href = '/login';
    }
    const data = await response.json();
    return data;    
  }
  async function fetchFaesser() {
    const  profiles  = await fetchData(`${CONFIG.BACKEND_URL}/admin/faesser`);
    console.log(profiles)
    setFaesser(JSON.parse(profiles));
  }
  async function fetchInfo(index) {
    let info = "get_bier"
    if(index === 1){
      info= "get_bier"
    }else if (index === 2){
      info= "get_fuchsenkiosk"
    }else if (index === 3){
      info= "get_wasser"
    }else if (index === 4){
      info= "get_limo"
    }else if (index === 5){
      info= "get_waesche"
    }
    const  profiles  = await fetchData(`${CONFIG.BACKEND_URL}/admin/${info}`);
    console.log(profiles)
    setBier(profiles);
    setKeys(Object.keys(profiles[0]).filter(x => x!== "id"))
  }
  useEffect(() => {  
    
    fetchFaesser();
  }, []);

  const deleteALL = async () => {
    const result = window.confirm('Sehr sicher das du ALLES löschen willst?');
    if (result) {
      setDeletingAction(true)
      console.log('test');
      const  profiles  = await fetchData(`${CONFIG.BACKEND_URL}/admin/delete_all`);

      setDeletingAction(false);
      fetchFaesser();
      alert("Löschen erfolgreich!")
    }
  }
  const switchTab = (index) => {
    try{
      for(let i = 0; i < buttons.length; i+=1){
        if(buttons[i] !== undefined && buttons[i].current !== undefined)
          buttons[i].current.style.backgroundColor  = '#000'
      }  
    }catch(e){
      console.log("err")
    }
    try{    
      if(buttons[index] !== undefined && buttons[index].current !== undefined)
        buttons[index].current.style.backgroundColor  = BackColors[index];
    }catch(e){
      console.log("err")
    }
    setCurrTabIndex(index)
    setCurrColor(BackColors[index])
 
      fetchInfo(index)
    
  }
  return (
    <>
      <Helmet>
        <title> Kasse </title>
      </Helmet>
    <div style={{width: "300px", position: "fixed", background: "#ebeef1", height: "100%", bottom: 0, top: 0, left: 0, zIndex: 300}}>
      <Box sx={{ mb: 5, mx: 2.5, marginTop: "20px" }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={"a"} alt={"Kasse"}/>

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                Kasse
              </Typography>

              
            </Box>
          </StyledAccount>
        </Link>
      </Box>
      <NavSection data={navKasse} /> 
</div>
      <Container style={{marginLeft: "300px"}}>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center', marginLeft: "0px" }}>

          <Typography variant="h3" paragraph >
           Delta Drinks Kasse
          </Typography>

          <Button variant="contained" onClick={downloadCsv} disabled={downloading} style={{height: "50px", width: "200px"}}>Download Excel</Button>
          <Button variant="contained" onClick={deleteALL} disabled={deletingAction} style={{marginTop: "10px", background : "#303030"}}>Delete ALL</Button>
          
          <div style={{ display: (deletingAction ?  "flex" : "none"), marginTop: "20px"}}>
            <CircularProgress  style={{color: '#000'}}/>
            </div>

            <div style={{ zIndex: 1000, width:"100%", paddingBottom: "5px",  margin: "0em auto", marginTop: "10px", borderRadius: "10px"}}>
              <div style={{ display: "flex"}}>
            {TabNames.map((item, index) => {
                  return (
                  <Button variant="contained" onClick={() => switchTab(index)}  ref={buttons[index]} style={{  transition: "background-color 0.1s ease opacity 0.3s ease, filter 0.3s ease", height: "70px", width: "20%" ,borderRadius: "0px", border: "1px solid #ccc", background: (item === "Fässer" ? BackColors[0] : '#000') }}>{item}</Button>
                  )
              })}</div>
            </div>
          
            {currTabIndex > 0 && (
              <label htmlFor="show-active-rows">
                <input
                  id="show-active-rows"
                  type="checkbox"
                  checked={showOnlyActive}
                  onChange={(e) => setShowOnlyActive(e.target.checked)}
                />
               Zeige nur Einträge mit Strichen
              </label>
            )}

          {currTabIndex > 0 && (<div style={{ marginTop: "10px",paddingBottom: "30px", marginLeft: "3px", marginRigth: "10px",  width: '100%',overflowX: "auto"}}>
          <table style={{ padding: '25px',  marginTop: '5px', borderCollapse: "collapse"}}>
          <thead>
              <tr>
                {bier.length > 0 && keys.map(key => (
                  <th key={key} style={{  padding: '10px', border: '1px solid #000' }}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
                {bier.length > 0 && bier.map((item, index) => {
                  const shouldDisplay = keys.some(key => item[key] > 0);

                  // Only render the row if showOnlyActive is false or if the item should be displayed
                  if (!showOnlyActive || shouldDisplay) {
                    return (
                      <tr key={index}>
                        {keys.map(key => (
                          <td style={{ padding: '10px', textAlign: 'left', border: '1px solid #000' }}>
                            {item[key]}
                          </td>
                        ))}
                      </tr>
                    );
                  }

                  // Return null if the row should not be displayed
                  return null;
                })}
              </tbody>

              </table>
          </div>)}
          {currTabIndex === 0 && (<div style={{ marginTop: "10px",paddingBottom: "30px"}}>
            <Typography sx={{width: "100%", textAlign:"center", marginTop: "5px"}}>
              Noch abzurechende Fässer
            </Typography>
          <table style={{ width: '100%', padding: '25px',  marginTop: '5px'}}>
                <tbody>
                  
                    <tr>
                      <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>Nr</td>
                      <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>Striche</td>
                      <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>Freifassname</td>
                      <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>Date</td>
                    </tr>
                    
                  {faesser.faesser
                    .filter(item => item.id.toString() !== faesser.active_fass.toString())
                    .map((item, index) => (
                  <tr key={index}>
                  <td style={{  padding: '10px', border: '1px solid #000' }}>
                    {item.id}
                  </td>
                  <td style={{  padding: '10px', border: '1px solid #000', color: (item.striche < 100 ? '#f00' : undefined) }}>
                    {item.striche < 0 ? 'FREIFASS' : item.striche}
                  </td>
                  <td style={{  padding: '10px', border: '1px solid #000' }}>
                    {item.description}
                  </td>
                  <td style={{  padding: '10px', border: '1px solid #000' }}>
                    {item.date}
                  </td>
                  
                </tr>
                ))}

                </tbody>

              </table>
          </div>)}
        </StyledContent> 
      </Container>
    </>
  );
}
