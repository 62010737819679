import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled  } from '@mui/material/styles';

import { Button, Grid, CardHeader, IconButton, Avatar, Card,Typography, Container, Box, CircularProgress  } from '@mui/material';
import { useState ,useEffect, useRef  } from 'react';
import Iconify from '../components/iconify';
import CONFIG from "../config";
// ----------------------------------------------------------------------



export default function DrinksPage() {

    const items = ["Bier", "Fuchsenkiosk", "Wasser/Oe Pullen", "Limo/ACE", "Wäsche", "Kopien", "Fass", "Glas"];
    const images = ["bier", "shop", "water", "water", "laundry", "copies", "fass", "glas"];
    const BackColors = ["#bc6c25", "#5b9c6b", "#669cb6", "#7c538f", "#bfa82c", "#999", "#999", "#999"]
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fuchsenkiosk, setFuchsenkiosk] = useState(0);
    const [limo, setLimo] = useState(0);
    const [waesche, setWaesche] = useState(0);
    const [fass, setFass] = useState(0);
    const [glas, setGlas] = useState(0);
    const [wasser, setWasser] = useState(0);
    const [kopien, setKopien] = useState(0);
    const [bier, setBier] = useState(0);
    const [uID, setUID] = useState(-1);
    const counts = [bier, fuchsenkiosk, wasser, limo, waesche, kopien, fass, glas ]
    async function postData(url, requestBody) {
        const token = localStorage.getItem('deltatoken');
        const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        return data;
    }

    async function getData(url) {
        const token = localStorage.getItem('deltatoken');
        const response = await fetch(url, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${token}` ,
          'Content-Type': 'application/json',}
        });
        const data = await response.json();
        return data;
      }
      function parseTransactionData(input){
        console.log(input);
            const original = input;
            setFuchsenkiosk(original.fuchsenkiosk || 0)
            setLimo(original.limo || 0)
            setWasser(original.wasser || 0)
            setKopien(original.kopien || 0)
            setFass(original.fass || 0)
            setWaesche(original.waesche || 0)
            setGlas(original.glas || 0);
            setLoading(false)
      }
      const [clickedIndex, setClickedIndex] = useState(null);

    useEffect(() => {  
        async function fetchUsers() {  
            const  input  = await postData(`${CONFIG.BACKEND_URL}/get_transactions`, {input: uID.toString()});
            parseTransactionData(input)
        }
        fetchUsers();
      }, []);
      const insert = async(value) => {
        const  input  = await postData(`${CONFIG.BACKEND_URL}/post_transaction`, {category: value, amount: 1, user: uID});
        parseTransactionData(input)
      }
      
      const handleIncrement = async(c, value) => {
        setClickedIndex(value);
        console.log(c)
        if (c > 0){
            const  input  = await postData(`${CONFIG.BACKEND_URL}/post_transaction`, {category: value, amount: 1, user: uID});
            parseTransactionData(input)
        }else{
            const  input  = await postData(`${CONFIG.BACKEND_URL}/post_transaction`, {category: value, amount: -1, user: uID});
            parseTransactionData(input)
        }
        setTimeout(() => {
          setClickedIndex(null);
        }, 50);
      };
  return (
    <>
      <Helmet>
        <title> Delta Drinks </title>
      </Helmet>

      <Container>
      <Grid container spacing={2} style={{filter: loading ? "blur(5px)": undefined, marginTop: "-25px"}}> 
        <p style={{color: '#000', width: '100%',  textAlign: 'center', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px'}}>Striche sind mit dem Tablet im Keller synchronisiert beim klicken auf plus oder minus. Dies ist dein Stand seit der letzten Kasse</p>     
      {items.map((item, index) => {
              const isHovered = index === hoveredIndex;

              const cardStyle = {
                height: '170px',
                cursor: 'pointer',
                backgroundColor: clickedIndex === index ? BackColors[index] : '#e1e4e9', 
                transition: "background-color 0.05s ease",
              };

              return (
                <Grid item xs={12} md={6} lg={4} key={item.id} style={{display: (item === "Bier" ? "none" : undefined)}}>
                  <Card
                    style={cardStyle}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                  <CardHeader title={item} style={{ textAlign: 'center' }} />
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <IconButton onClick={() => handleIncrement(-1, index)}>
                                <Iconify icon="eva:minus-outline" width={64} />
                            </IconButton>
                            
                            <Avatar sx={{
                                position: 'relative',
                                width: '90px',
                                height: '90px',
                                background: '#fff0',
                                margin: '0 20px',
                            }}>
                                <img
                                    style={{ objectFit: 'cover', zIndex: 5, padding: '10px' }}
                                    src={`/assets/${images[index]}.png`}
                                    alt="T"
                                />
                            </Avatar>
                            
                            <IconButton onClick={() => handleIncrement(1, index)}>
                                <Iconify icon="eva:plus-outline" width={64} />
                            </IconButton>
                        </div>
                          <p style={{textAlign: 'center', marginTop: "-5px"}}>{counts[index]} {counts[index] === 1 ? "Strich" : "Striche"}</p>
                  </Card>
                </Grid>
              );
            })}
            </Grid>
            <div style={{position: "fixed", zIndex: 1000, top: "50%", height: "100vh",  
             left:"50%",transform: "translate( -50%)",
             textAlign: "center", display: (loading ?  "flex" : "none")}}>
            <CircularProgress />
            </div>


      </Container>
    </>
  );
}
