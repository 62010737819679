import { Helmet } from 'react-helmet-async';
import { Button, Dialog, IconButton, DialogContent, TextField, Fab, DialogTitle, Grid, CircularProgress, CardHeader, Avatar, Card, Typography, Container, Box, Checkbox } from '@mui/material';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import debounce from 'lodash.debounce';
import { Icon } from '@iconify/react';
import Iconify from '../components/iconify';
import styles from './Bubble.css';
import CONFIG from "../config";

const Fav = styled('fab')(({ theme }) => ({
  position: 'fixed',
  zIndex: 500,
  bottom: theme.spacing(3),
  right: theme.spacing(3),
}));
const FavStats = styled('fab')(({ theme }) => ({
  position: 'fixed',
  zIndex: 500,
  bottom: theme.spacing(3),
  left: theme.spacing(3),
}));

const TabNames = ["Bier", "fuchsenkiosk", "wasser/Pullen", "limo", "Wäsche"];
const BackColors = ["#bc6c25", "#5b9c6b", "#669cb6", "#7c538f", "#bfa82c", "#144b74"];
const images = ["bier", "shop", "water", "water", "laundry", "copies", "fass", "glas"];

export default function DrinksTablet() {
  const fields = useRef([]);
  const buttons = TabNames.map(() => React.createRef());

  const [state, setState] = useState({
    currHighlightedUsername: "",
    hoveredIndex: null,
    available: false,
    dialogOpen: false,
    dialogHighscores: false,
    dialogOpenPeople: false,
    dialogOpenFreifass: false,
    showFreifass: false,
    fuchsenkiosk: 0,
    tempFreifassName: "",
    names: Array(80).fill({ name: "", striche: 0 }),
    pureNames: Array(80).fill(""),
    limo: 0,
    allNames: [{"id": 0}],
    easterEgg: {},
    waesche: 0,
    fass: 0,
    freifass: "",
    countStriche: 0,
    glas: 0,
    wasser: 0,
    kopien: 0,
    bier: 0,
    currIndex: 0,
    count: 0,
    freifassLoading: false,
    loading: true,
    loading2: false,
    currTabIndex: 0,
    highscores: [],
    faesser: { faesser: [], active_fass: -1 },
    displayHighscores: [],
    currColor: "#bc6c25",
    activateEasterEgg: {},
    isMobile: false,
    isLongPressing: false,
    clickedIndex: null,
    longClickedIndex: null,
    currentColorIndex: 0,
    showFreifassSelection: false
  });

  const {
    currHighlightedUsername, hoveredIndex, available, easterEgg, dialogOpen, dialogHighscores, dialogOpenPeople, showFreifassSelection, dialogOpenFreifass, showFreifass, fuchsenkiosk, tempFreifassName, names, pureNames, limo, allNames, waesche, fass, freifass, countStriche, glas, wasser, kopien, bier, currIndex, count, freifassLoading, loading, loading2, currTabIndex, highscores, faesser, displayHighscores, currColor, activateEasterEgg, isMobile, isLongPressing, clickedIndex, longClickedIndex, currentColorIndex
  } = state;

  const mutedColors = ['#A18B76', '#8B9EA9', '#9EA39C', '#A6958E', '#9AA6BD'];
  const currentColor = mutedColors[currentColorIndex];

  const handleStateUpdate = (newState) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  const drawStrokes = useCallback((c, count) => {
    if (c === null) return 0;
    const ctx = c.getContext('2d');
    let top = 0;
    c.width = c.clientWidth;
    c.height = 50;
    let start = 1;
    if (count < 50) {
      start = 250 - (count * 10 / 2);
    }
    ctx.scale(0.5, 0.5);
    for (let i = 1; i <= Math.min(count, 150); i += 1) {
      ctx.beginPath();
      ctx.lineWidth = "4";
      ctx.strokeStyle = currColor;
      start += 10;
      if (i % 5 === 0 && i > 1) {
        ctx.beginPath();
        ctx.moveTo(start - 50, 10 + top);
        ctx.lineTo(start, 25 + top);
        ctx.stroke();
      } else {
        ctx.rect(start, 10 + top, 1, 20);
        ctx.stroke();
      }
      if (i === 50) {
        top += 40;
        start = 1;
      }
      if (i === 100) {
        top += 40;
        start = 1;
      }
    }
    return 0;
  }, [currColor]);

  const postData = async (url, requestBody) => {
    const token = localStorage.getItem('deltatoken');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody),
    });
    if (response.status === 401) {
      window.location.href = '/login';
    }
    if (response.ok) {
      const data = await response.json();
      return data;
    }
    return null;
  };

  const fetchData = async (url) => {
    const token = localStorage.getItem('deltatoken');
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    if (response.status === 401) {
      window.location.href = '/login';
    }
    const data = await response.json();
    return data;
  };

  const parseStriche = useCallback((input) => {
    if (input === null) return 0;
    handleStateUpdate({ loading: true });

    const items = JSON.parse(input);

    //handle freifass view
    if (currTabIndex === 0 && "freifass" in items[items.length - 1]) {
      console.log("FREIFASS");
      handleStateUpdate({ freifass: items[items.length - 1].corpsbruder, loading: false });
      return 0;
    }

    handleStateUpdate({ freifass: "", showFreifass: false });
    const tempNames = items.map((item) => ({
      name: item.corpsbruder,
      striche: item.count,
      id: item.id
    }));
    handleStateUpdate({ names: tempNames, pureNames: tempNames.map((item) => (item.name in activateEasterEgg ? activateEasterEgg[item.name] : item.name)), 
      countStriche: items.reduce((sum, obj) => sum + obj.count, 0) });

    for (let i = 0; i < items.length; i += 1) {
      try {
        const canvas = document.getElementById(`field_${i}`);
        drawStrokes(canvas, tempNames[i].striche);
      } catch (e) {
        console.log(`Error, ${e}`);
      }
    }

    handleStateUpdate({ loading: false });
    return 0;
  }, [activateEasterEgg, currTabIndex, drawStrokes]);

  const showHighscores = async () => {
    const input = await postData(`${CONFIG.BACKEND_URL}/get_all_striche`, { input: 5 });

    const splitHighscores = JSON.parse(input).reduce((result, currentScore) => {
      const existingCategory = result.find(category => category[0].category === currentScore.category);

      if (existingCategory) {
        existingCategory.push(currentScore);
      } else {
        result.push([currentScore]);
      }

      return result;
    }, []);
    console.log(splitHighscores);
    handleStateUpdate({ highscores: splitHighscores, loading: false, loading2: false, displayHighscores: true, dialogHighscores: true });
  };

  const saveClose = async () => {
    handleStateUpdate({ longClickedIndex: null, showFreifassSelection: false, dialogOpen: false});
    const input = await postData(`${CONFIG.BACKEND_URL}/post_transaction_admin`, { singleTap: false, category: currTabIndex, amount: count, user: names[currIndex].id });
    parseStriche(input);
  };

  const saveCloseFreifass = async () => {
    if (tempFreifassName.length <= 2) {
      alert("Freifassname ist zu kurz!");
      return;
    }
    handleStateUpdate({ freifassLoading: true });
    const input = await postData(`${CONFIG.BACKEND_URL}/neues_freifass`, { input: tempFreifassName });
    parseStriche(input);
    handleStateUpdate({ dialogOpenFreifass: false, loading2: false, freifassLoading: false, showFreifassSelection: false });
  };

  const handleGlaskasse  = async () => {
    const result = window.confirm('Sicher das du ein Glas abrechnen möchtestest?');
    if (result) {
      const input = await postData(`${CONFIG.BACKEND_URL}/admin/glaskasse`, { input: names[currIndex].id  });
      alert(input.success);
    }
  }
  const handleClose = () => {
    handleStateUpdate({ longClickedIndex: null, dialogOpen: false });
  };

  const handleCloseFreifass = () => {
    handleStateUpdate({ dialogOpenFreifass: false });
  };

  const handleClosePeople = () => {
    handleStateUpdate({ showFreifassSelection: false, dialogOpenPeople: false });
  };

  const saveClosePeople = async () => {
    handleClosePeople();
    const input = await postData(`${CONFIG.BACKEND_URL}/update_names`, { input: allNames });
    window.location.reload();
  };

  const handleResize = () => {
    handleStateUpdate({ isMobile: window.innerWidth <= 500 });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleIncrement = (c) => {
    if (count + c < 999) {
      handleStateUpdate({ count: count + c });
    } else {
      handleStateUpdate({ count: 999 });
    }
  };

  const handleDecrement = () => {
    if (count > 0) {
      handleStateUpdate({ count: count - 1 });
    }
  };

  const closeHighscores = () => {
    handleStateUpdate({ dialogHighscores: false });
  };
  const longPressTimeout = useRef(null);
  const touchMoved = useRef(false);
  
  const handleMouseDown = (index) => {
    touchMoved.current = false; // Reset touchMoved state
    longPressTimeout.current = setTimeout(() => {
      if (!touchMoved.current) {
        handleStateUpdate({
          count: names[index].striche,
          currHighlightedUsername: pureNames[index],
          dialogOpen: true,
          currIndex: index,
          isLongPressing: true,
          longClickedIndex: index,
        });
      }
    }, 800);
  };
  
  const handleTouchMove = () => {
    touchMoved.current = true;
    clearTimeout(longPressTimeout.current);
  };
  
  const handleMouseUp = () => {
    clearTimeout(longPressTimeout.current);
    if (isLongPressing) {
      handleStateUpdate({ isLongPressing: false });
    }
  };
  
  const handleTouchEnd = () => {
    handleMouseUp();
    touchMoved.current = false; // Reset touchMoved state
  };
  
  
  useEffect(() => {
    if (dialogOpen) {
      clearTimeout(longPressTimeout.current);
      if (isLongPressing) {
        handleStateUpdate({ isLongPressing: false });
      }
    }
  }, [dialogOpen]);
  

  useEffect(() => {
    if (dialogOpen) {
      clearTimeout(longPressTimeout);
      if (isLongPressing) {
        handleStateUpdate({ isLongPressing: false });
      }
    }
  }, [dialogOpen]);

  let timerFreifass;
  useEffect(() => {
    if (loading) {
      timerFreifass = setTimeout(() => {
        handleStateUpdate({ loading2: true });
      }, 1000);
    } else {
      handleStateUpdate({ loading2: false });
    }

    return () => {
      clearTimeout(timerFreifass);
    };
  }, [loading]);

  const switchTab = (index) => {
    try {
      for (let i = 0; i < buttons.length; i += 1) {
        if (buttons[i] !== undefined && buttons[i].current !== undefined)
          buttons[i].current.style.backgroundColor = '#000';
      }
    } catch (e) {
      console.log(`Error, ${e}`);
    }
    try {
      if (buttons[index] !== undefined && buttons[index].current !== undefined)
        buttons[index].current.style.backgroundColor = BackColors[index];
    } catch (e) {
      console.log(`Error, ${e}`);
    }
    handleStateUpdate({ currTabIndex: index, currColor: BackColors[index] });
  };

  const neuesFass = async () => {
    const result = window.confirm('Sicher das du zu einem neuen Fass wechseln möchstest?');
    if (result) {
      handleCloseFreifass();
      const input = await postData(`${CONFIG.BACKEND_URL}/neues_fass`, { input: (freifass.length === 0 ? "false" : "true") });
      parseStriche(input);
      handleStateUpdate({ loading2: false });
    }
  };

  const moveToFass = async (id) => {
    const result = window.confirm(`Sicher das du zum Fass Nr ${id} wechseln möchstest?`);
    if (result) {
      const input = await postData(`${CONFIG.BACKEND_URL}/admin/move_to_fass`, { input: id });
      console.log("MOVE TO", id);
      handleCloseFreifass();
      window.location.reload();
    }
  };

  const corpsbruderName = (corpsbruder, requireFull = false) => {
    const user = allNames.find(item => item.id === corpsbruder);
    if (user) {
      if (requireFull){
        corpsbruder = `${user.first_name} ${user.second_name}`;
      }else{
        corpsbruder = user.username;
      }
      
      if (user.birthday){
        const corpsbruderBirthday = new Date(user.birthday)
        const today = new Date();
        if (
          corpsbruderBirthday.getMonth() === today.getMonth() &&
          corpsbruderBirthday.getDate() === today.getDate()
        ) {
          console.log("CELEBRATE");
          return `${corpsbruder} 🎉 `
        }
      }
    }
    return corpsbruder;
  }

  // TODO
  /*useEffect(() => {
    const token = localStorage.getItem('deltatoken');
    const ws = new WebSocket(`ws://${CONFIG.BACKEND_URL.replace('http://', '')}/ws?token=${token}`);

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log("RECEIVED DATA");
      console.log(message);
    };

    return () => {
      ws.close();
    };
  }, []); */

  const handleRowClick = async (index) => {
    if (freifass.length === 0) {
      const input = await postData(`${CONFIG.BACKEND_URL}/post_transaction_admin`, { singleTap: true, category: currTabIndex, amount: names[index].striche + 1, user: names[index].id });
      parseStriche(input);
      handleStateUpdate({ clickedIndex: index });

      setTimeout(() => {
        handleStateUpdate({ clickedIndex: null });
      }, 150);
    }
  };

  const StyledContent = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: "100px"
  }));

  const btnStyle = { background: '#222', color: '#ccc', borderRadius: "0px", width: "25%", height: "70px", border: "1px solid #aaa", padding: "10px" };
  const btnStyleRunde = { background: '#222', color: '#ccc', borderRadius: "0px", width: "100%", height: "70px", border: "1px solid #aaa", padding: "10px" };

  useEffect(() => {
    const interval = setInterval(() => {
      handleStateUpdate({ currentColorIndex: (currentColorIndex + 1) % mutedColors.length });
    }, 60000);

    return () => clearInterval(interval);
  }, [currentColorIndex]);

  useEffect(() => {
    const loadFaseer = async () => {
      const faesser = await fetchData(`${CONFIG.BACKEND_URL}/admin/faesser`);
      console.log(faesser);
      handleStateUpdate({ faesser: JSON.parse(faesser) });
    };
    loadFaseer();
  }, [dialogOpenFreifass]);

  const handleEasterEgg = () => {
    if (names[currIndex].name in activateEasterEgg) {
      const updatedEasterEgg = { ...activateEasterEgg };
      delete updatedEasterEgg[names[currIndex].name];
      handleStateUpdate({ activateEasterEgg: updatedEasterEgg, currHighlightedUsername: names[currIndex].name });
    } else if (names[currIndex].name in easterEgg) {
      const updatedEasterEgg = { ...activateEasterEgg, [names[currIndex].name]: `${easterEgg[names[currIndex].name]}` };
      handleStateUpdate({ activateEasterEgg: updatedEasterEgg, currHighlightedUsername: easterEgg[names[currIndex].name] });
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleCheckboxChange = (index) => {
    const updatedNames = [...allNames];
    updatedNames[index] = {
      ...updatedNames[index],
      is_displayed: !updatedNames[index].is_displayed,
    };
    handleStateUpdate({ allNames: updatedNames });
  };

  useEffect(() => {
    const getAllNames = async () => {
      let input = await postData(`${CONFIG.BACKEND_URL}/get_all_names`, { input: "all" });
      input = JSON.parse(input);
      console.log(input);

    

      if (input !== null){
        const nicknameMapping = input.reduce((acc, item) => {
          if (item.nickname != null && item.nickname.length > 0) {
            acc[item.id] = item.nickname;
          }
          return acc;
        }, {});

        handleStateUpdate({ allNames: input, easterEgg: nicknameMapping });

      }
    };
    getAllNames();
  }, [dialogOpenPeople]);

  useEffect(() => {
    const fetchStriche = async () => {
      handleStateUpdate({ loading: true });
      const input = await postData(`${CONFIG.BACKEND_URL}/get_all_striche`, { input: currTabIndex });
      parseStriche(input);
      handleStateUpdate({ loading: false });
    };
    fetchStriche();
  }, [currTabIndex, parseStriche]);

  return (
    <div style={{ WebkitUserSelect: "none", MozUserSelect: 'none', msUserSelect: 'none', userSelect: 'none' }}>
      <Helmet>
        <title> Delta Drinks </title>
      </Helmet>
      <Dialog
        open={dialogOpenPeople}
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          style: {
            position: 'fixed',
            width: '100%',
            background: '#222',
            color: '#fff',
            minWidth: '440px',
          },
        }}
      >
        <div style={{ background: '#222', color: '#fff', minWidth: '440px' }}>
          <DialogTitle style={{ color: '#fff', fontSize: 30, textAlign: 'center', padding: '20px' }}>
            Angezeigte Corpsbrüder ändern
          </DialogTitle>
          <p style={{textAlign: 'center', color: '#ccc'}}>Auf einzelne Namen klicken um diese ein - oder auszublenden</p>

          <div>
            <div
              style={{
                width: '100%',
                height: '350px',
                maxHeight: '350px',
                overflowY: 'auto',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '10px',
                padding: '0 20px',
                boxSizing: 'border-box',
              }}
            >
              {allNames.map((item, index) => (
                <div
                  key={item.id}
                  role="button"
                  tabIndex={0}
                  style={{
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    width: 'calc(50% - 10px)',
                  }}
                  onClick={() => handleCheckboxChange(index)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleCheckboxChange(index);
                    }
                  }}
                >
                  <span
                    style={{
                      padding: '17px',
                      borderRadius: '4px',
                      color: '#fff',
                      background: '#454545',
                      width: '100%',
                      backgroundColor: item.is_displayed ? '#0a2f69' : '#454545',
                    }}
                  >
                    {item.username}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              paddingTop: '10px',
            }}
          >
            <Button
              style={{
                flexBasis: '50%',
                height: '70px',
                color: '#26b4ec',
                background: '#454545',
                border: '1px solid black',
              }}
              onClick={handleClosePeople}
            >
              Abbrechen
            </Button>
            <Button
              variant="contained"
              style={{
                flexBasis: '50%',
                height: '70px',
                background: '#0a2f69',
                border: '1px solid black',
              }}
              onClick={saveClosePeople}
            >
              Speichern
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog open={showFreifassSelection} disableBackdropClick disableEscapeKeyDown PaperProps={{
        style: {
          position: 'fixed',
          width: '100%',
          background: '#222',
          color: '#fff',
          minWidth: '440px',
        },
      }}>
        <div style={{ background: '#222', color:'#fff', minWidth: "530px"}}>
        <DialogTitle style={{color: '#fff', fontSize: 30, textAlign: "center"}}>Freifass geben</DialogTitle>
        <TextField
          value={tempFreifassName}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (inputValue.length <= 100) {
                handleStateUpdate({tempFreifassName: inputValue})
            }
          }}
          fullWidth
          multiline
          style={{ background: '#eee', padding: '10px' }}
          placeholder='Freifass Name'
          inputProps={{ maxLength: 100 }}
        />
          <div  style={{display: freifassLoading? "flex" : "none", color: "#fff", marginBottom: "-10px", height: "100px", padding:"10px", justifyContent: "center", alignItems: "center", flexDirection:"column"}} >
          <CircularProgress id="my-spinner" style={{color: "#fff"}}/></div>
            <div style={{marginTop:"10px"}}>
            <Button  disabled={freifassLoading}  style={{width: "50%", height: "70px", color: '#26b4ec', background: '#454545'}} 
            onClick={() => { handleStateUpdate({ showFreifassSelection: false }) }}>Abbrechen</Button>
            <Button  disabled={freifassLoading}  variant="contained" style={{width: "50%", height: "70px", background: '#0a2f69'}} 
            onClick={saveCloseFreifass}>Speichern</Button></div>
        </div>
    </Dialog>


      <Dialog open={dialogOpenFreifass} disableBackdropClick disableEscapeKeyDown PaperProps={{
        style: {
          position: 'fixed',
          width: '100%',
          background: '#222',
          color: '#fff',
          minWidth: '440px',
        },
      }}>

        <DialogTitle style={{ color: '#fff', fontSize: 30, textAlign: "center" }}>
          Bisherige Fässer
        </DialogTitle>

        <table style={{
          width: '100%',
          marginTop: '5px',
          color: "#eee",
          tableLayout: 'fixed'
        }}>
          <tbody style={{
            width: "100%",
            padding: '25px',
            paddingTop: '5px',
            height: '350px',
            maxHeight: "350px",
            overflowY: 'auto',
            display: 'block'
          }}>
            <tr>
              <td style={{
                padding: '10px',
                textAlign: 'left',
                border: '1px solid #000',
                width: '10%'
              }}>Nr</td>
              <td style={{
                padding: '10px',
                textAlign: 'left',
                border: '1px solid #000',
                width: '10%'
              }}>Striche</td>
              <td style={{
                padding: '10px',
                textAlign: 'left',
                border: '1px solid #000',
                width: '30%'
              }}>Date</td>
              <td style={{
                padding: '10px',
                textAlign: 'left',
                border: '1px solid #000',
                width: '50%'
              }}>Choose</td>
            </tr>

            {faesser.faesser.map((item, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: faesser.active_fass.toString() === item.id.toString() ? '#669cb6' : undefined,
                }}
              >
                <td style={{ padding: '10px', border: '1px solid #000' }}>
                  {item.id}
                </td>
                <td style={{ padding: '10px', border: '1px solid #000', color: (item.striche < 100 ? '#f00' : undefined) }}>
                  {item.striche < 0 ? 'FREIFASS' : item.striche}
                </td>

                <td style={{ padding: '10px', border: '1px solid #000' }}>
                  {item.date}<br/>{item.striche < 0 ? `Abgerechnet auf: ${item.description}`: item.description}
                </td>

                <td style={{ padding: '10px', border: '1px solid #000', display: faesser.active_fass.toString() === item.id.toString() ? 'none' : undefined }}>
                  <button style={{
                    height: "70px",
                    color: '#fff',
                    background: '#333',
                    fontSize: "14px",
                    borderRadius: '8px',
                    padding: '12px 20px',
                    border: 'none',
                    transition: 'background-color 0.3s ease',
                  }} onClick={() => moveToFass(item.id)}>Zu diesem Fass wechseln</button>
                </td>
                <td style={{ padding: '10px', border: '1px solid #000', display: faesser.active_fass.toString() === item.id.toString() ? undefined : 'none' }}>
                  Aktuelles Fass
                </td>

              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ padding: "10px", background: '#000', display: 'flex', justifyContent: 'space-between' }}>

          <Button
            onClick={() => { handleStateUpdate({ dialogOpenFreifass: false, showFreifassSelection: true }) }} 
            disabled={freifassLoading}
            style={{
              width: "48%",
              height: "70px",
              color: '#fff',
              background: '#333',
              borderRadius: '8px',
              padding: '12px 20px',
              border: 'none',
              fontSize: '16px',
              transition: 'background-color 0.3s ease',
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#444';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = '#333';
            }}
          >
            Freifass geben
          </Button>
          <Button
            onClick={neuesFass}
            disabled={freifassLoading}
            style={{
              width: "48%",
              height: "70px",
              color: '#fff',
              background: '#333',
              borderRadius: '8px',
              padding: '12px 20px',
              border: 'none',
              fontSize: '16px',
              transition: 'background-color 0.3s ease',
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#444';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = '#333';
            }}
          >
            Neues Fass
          </Button>
        </div>

        <Button disabled={freifassLoading} style={{ width: "100%", height: "70px", color: '#26b4ec', background: '#454545' }} onClick={handleCloseFreifass}>Abbrechen</Button>
      </Dialog>

      <Dialog open={dialogOpen} disableBackdropClick disableEscapeKeyDown PaperProps={{
        style: {
          position: 'fixed',
          width: '100%',
          background: '#222',
          color: '#fff',
          minWidth: '440px',
          overflow: 'auto', // Enable scrolling if the content exceeds the viewport
        },
      }}>
        <div style={{ background: '#222', color: '#fff', minWidth: "330px" }}>
          <DialogTitle style={{ color: '#fff', fontSize: 35 }} onClick={() => handleEasterEgg()}>{corpsbruderName(currHighlightedUsername, true)}</DialogTitle>
          <p style={{ color: '#fff', marginLeft: "25px", marginTop: "-15px" }}>Bisher {names[currIndex].striche} Striche bei {TabNames[currTabIndex]}.<br /> Gebe insgesamte Strichanzahl an:</p>
          <div style={{ textAlign: 'center', fontSize: '44px', padding: '20px' }}>
            <IconButton onClick={handleDecrement}>
              <Iconify icon="eva:minus-outline" width={64} style={{ marginRight: "30px" }} />
            </IconButton>
            {count}
            <IconButton onClick={() => handleIncrement(1)} style={{ marginLeft: "30px" }}>
              <Iconify icon="eva:plus-outline" width={64} />
            </IconButton>
          </div>
        </div>
        <div style={{ textAlign: 'center', fontSize: '44px', padding: '20px', background: "#111" }}>
          <Button color={"primary"} style={btnStyle} onClick={() => handleIncrement(4)} >+4</Button>
          <Button color={"primary"} style={btnStyle} onClick={() => handleIncrement(8)} >+8</Button>
          <Button color={"primary"} style={btnStyle} onClick={() => handleIncrement(16)} >+16</Button>
          <Button color={"primary"} style={btnStyle} onClick={() => handleIncrement(32)} >+32</Button>
          {currTabIndex === 0 && (<Button color={"primary"} style={btnStyleRunde} onClick={ handleGlaskasse }>Glaskasse</Button>)}
          <div style={{ marginTop: "50px" }}>
            <Button style={{ width: "50%", height: "70px", color: '#26b4ec', background: '#454545' }} onClick={handleClose}>Abbrechen</Button>
            <Button variant="contained" style={{ width: "50%", height: "70px", background: '#0a2f69' }} onClick={saveClose}>Speichern</Button></div>
        </div>
      </Dialog>
      <div style={{ position: "fixed", zIndex: 1000, width: "100%", paddingBottom: "5px", margin: "0em auto" }}>
        <div style={{ display: "flex" }}>
          {TabNames.map((item, index) => {
            return (
              <Button variant="contained" onClick={() => index === 5 ? showHighscores() : switchTab(index)} ref={buttons[index]} style={{ transition: "background-color 0.1s ease opacity 0.3s ease, filter 0.3s ease", height: "70px", width: "20%", borderRadius: "0px", border: "1px solid #ccc", background: (item === "Bier" ? BackColors[0] : '#000') }}>{item}</Button>
            )
          })}</div>
        {freifass.length === 0 && (
          <div style={{ height: "28px", textAlign: "center", color: "#fff", width: "100%", transition: "background-color 0.5s ease, opacity 0.3s ease, filter 0.3s ease", background: BackColors[currTabIndex] }}>
            <p style={{ background: "#0002", height: "100%", fontSize: 14, paddingTop: "3px" }}>{countStriche} {countStriche === 1 ? "Strich" : "Striche"} {currTabIndex === 0 ? `, Fass Nr. ${faesser?.active_fass?.toString() ?? 'N/A'}` : '' }</p>
          </div>)}
      </div>

      <Dialog open={dialogHighscores} maxHeight="50px">
        <DialogContent style={{ maxHeight: '70vh', overflowY: 'auto', background: "#303030" }}>

          <Button onClick={closeHighscores} variant="contained" style={{ position: "fixed", height: "55px", width: "120px", zIndex: 100, bottom: 10, left: "50%", transform: "translateX(-50%)" }}>
            Close
          </Button>
          {
            highscores.map((categoryItems, categoryIndex) => {
              return (
                <div style={{ maxWidth: "600px", margin: "0em auto", background: '#303030', width: "100%" }}>
                  <p style={{ color: '#fff', fontSize: "30px" }}>{capitalizeFirstLetter(categoryItems[0].category)}</p>
                  <Grid container spacing={1} style={{ paddingBottom: "100px" }}>
                    {categoryItems.map((item, index) => {
                      if (allNames.find(a => a.id === item.corpsbruder)){
                      const cardStyle = {
                        height: '100px',
                        cursor: 'pointer',
                        color: "#fff",
                        userSelect: "none",
                        backgroundColor: clickedIndex === index ? BackColors[currTabIndex] : '#1e1e1e',
                      };

                      const circleStyle = {
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        backgroundColor: '#000',
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '24px',
                        position: 'absolute',
                        zIndex: 10,
                        top: "25px",
                        left: '10px',
                      };

                      return (
                        <Grid item xs={6} md={6} lg={6} key={item.id} style={{ position: 'relative' }}>
                          <Card style={cardStyle}>
                            <div style={circleStyle}>{index + 1}</div>
                            <div style={{ background: BackColors[ TabNames.indexOf(item.category) ], position: "absolute", height: "100%", width: `${100 / Math.max(...categoryItems.map(x => x.count)) * item.count}%`, zIndex: -1 }} />

                            <CardHeader style={{ marginLeft: "40px", color: "#fff" }} title={corpsbruderName(item.corpsbruder)} subheader={<span style={{ color: '#ccc' }}>{`${item.count}`}</span>} />
                          </Card>
                        </Grid>
                      );
                    }

                    return null;
                    
                  })}
                  </Grid>
                </div>
              );
            })
          }
        </DialogContent>
      </Dialog>

      <div style={{ backgroundColor: (!showFreifass ? '#303030' : currentColor), transition: (!showFreifass ? " background-color 0.3s ease, opacity 0.3s ease, filter 0.3s ease" : " background-color 30s ease, opacity 0.3s ease, filter 0.3s ease"), padding: "10px", paddingTop: "80px", pointerEvents: (loading ? "none" : undefined), filter: (loading ? "blur(1px)" : undefined) }}>
        {showFreifass && (
          <section className={styles.sticky}>
            <div className="bubbles">
              <div className="bubble" />
              <div className="bubble" />
              <div className="bubble" />
              <div className="bubble" />
              <div className="bubble" />
              <div className="bubble" />
              <div className="bubble" />

            </div>
          </section>)}

        <Grid container spacing={1} style={{ marginTop: "40px", paddingBottom: "100px" }}>
          {pureNames.map((item, index) => {
            const isHovered = index === hoveredIndex;
            const cardStyle = {
              height: '100px',
              cursor: 'pointer',
              color: "#fff",
              userSelect: "none",
              //  backgroundColor: isHovered ? '#333' : '#1e1e1e', 
              backgroundColor: clickedIndex === index ? BackColors[currTabIndex] : (longClickedIndex === index ? '#000' : '#1e1e1e'),
            };
            return (
              <Grid item xs={6} md={6} lg={4} key={item.id}>
                <Card
                  style={cardStyle}
                  onMouseDown={() => handleMouseDown(index)}
                  onMouseUp={handleMouseUp}
                  onTouchStart={() => handleMouseDown(index)}
                  onTouchEnd={handleTouchEnd}
                  onTouchMove={handleTouchMove}
                  onClick={() => handleRowClick(index)}>

                  <p style={{ float: "left", marginLeft: "20px", marginTop: "20px", fontSize: 20 }}>{corpsbruderName(item)}</p>
                  <p style={{ padding: "5px", fontSize: 20, float: "right", marginRight: "10px" }}>{names[index].striche}</p>
                  <canvas id={`field_${index}`} style={{ width: '100%', paddingLeft: "20px", marginTop: "-20px" }} />
                </Card>
              </Grid>
            );
          })}
        </Grid>

        <div style={{ display: (freifass.length === 0 ? "none" : "flex"), background: currentColor, zIndex: 1, width: "100%", position: "fixed", left: 0, right: 0, bottom: 0, top: 0 }} />
        <div
          style={{
            marginTop: "-100px",
            position: "fixed", zIndex: 1100, top: "50%",
            left: "50%", transform: "translate( -50%)",
            textAlign: "center", display: (freifass.length > 0 ? "flex" : "none")
          }}
        >
          <Card style={{ background: '#000000bf', padding: '50px', backdropFilter: "blur(10px)" }}>
            <Typography variant="h1" paragraph style={{ color: '#fff', textAlign: "center" }}>
              {freifass}
            </Typography>
            <Typography variant="h4" paragraph style={{ color: '#ccc', textAlign: "center" }}>
              Freifass
            </Typography>
          </Card>
        </div>

      </div>

      <div style={{ position: "fixed", zIndex: 10, bottom: 0, left: 0, right: 0, paddingTop: "10px" }}>
      <Card style={{ 
          background: 'rgba(48, 48, 48, 0.5)',
          height: "70px", 
          marginLeft: "1px", 
          marginRight: "1px", 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '0px',
          padding: '0 10px',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
        }}>
          <Button 
            variant="contained" 
            onClick={() => { showHighscores() }} 
            style={{ 
              width: "30%",
              height: "80%",
              border: "1px solid #444", 
              background: '#000',
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '4px',
            }}
          >
            <Icon icon="bi:bar-chart-fill" /> {/* Bootstrap Icon */}
            Stats
          </Button>
          <Button 
            variant="contained" 
            onClick={() => { handleStateUpdate({ dialogOpenPeople: true }) }} 
            style={{ 
              width: "30%", 
              height: "80%", 
              border: "1px solid #444", 
              background: '#000',
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '4px',
            }}
          >
            <Icon icon="bi:people-fill" /> {/* Bootstrap Icon */}
            Corpsbrüder
          </Button>

          <Button 
            variant="contained" 
            onClick={() => {
              if (currTabIndex === 0) {
                handleStateUpdate({ dialogOpenFreifass: true, tempFreifassName: "" });
              } else {
                showHighscores();
              }
            }}            
            style={{ 
              width: "30%", 
              height: "80%", 
              border: "1px solid #444", 
              background: '#000',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '4px',
              color: currColor
            }}
          >
            <Icon icon="mdi:barrel-outline"  /> {/* Bootstrap Icon */}
            {currTabIndex === 0 ? 'Fässer' : TabNames[currTabIndex]}
          </Button>
        </Card>
      </div>

      

      <div style={{ position: "fixed", zIndex: 1000, top: "50%", height: "100vh",
        left: "50%", transform: "translate( -50%)",
        textAlign: "center", display: (loading2 ? "flex" : "none") }}>
        <CircularProgress style={{ color: '#fff' }} />
      </div>
    </div>
  );
}