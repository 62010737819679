import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled  , alpha} from '@mui/material/styles';

import { Button, CircularProgress, Card,Typography, Container,  Box, Link, Drawer, Avatar, Stac } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';


import Iconify from '../components/iconify';
import NavSection from '../components/nav-section';
import { navKasse } from '../layouts/dashboard/nav/config'
import CONFIG from "../config";
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
 
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: "30px"
}));

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const TabNames = ["Fässer", "Bier", "fuchsenkiosk", "wasser/Pullen", "limo", "Wäsche"]
const BackColors = ["#144b74","#bc6c25", "#5b9c6b", "#669cb6", "#7c538f", "#bfa82c"]
const buttons = TabNames.map(() => React.createRef());

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));
export default function Backup() {
    useEffect(() => {
        const token = localStorage.getItem('deltatoken');
        if (!token) {
          window.location.href = '/login';
          return;
        }
        fetch(`${CONFIG.BACKEND_URL}/protected`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${token}` }
        }).then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log(error);
          window.location.href = '/login';
        })    
      }, []);

  const [downloading, setDownloading] = useState(false);
  const [faesser, setFaesser] = useState([]);

  const downloadCsv = async () => {
    const token = localStorage.getItem('deltatoken');
  
    setDownloading(true)
    const response = await fetch(`${CONFIG.BACKEND_URL}/admin/excel`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const data = await response.json();
    
    if (response.ok) {
      if (data.notMatched.length > 0)
        alert(`ERROR! Folgende Corpsbrüder wurden nicht gemacht: ${data.notMatched}`);
      


      const fileUrl = `${CONFIG.BACKEND_URL}/kassenexl/${data.path}`;

      fetch(fileUrl)
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = data.path.split('/').pop();
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch(error => console.error('Error downloading file:', error));

    } else {
      console.error('Failed to download CSV file');
    }
    setDownloading(false)
  
  };
  async function postData(url, requestBody) {
    const token = localStorage.getItem('deltatoken');
    const response = await fetch(url, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(requestBody),
    });
    const data = await response.json();
    return data;
}
  
  async function restore(url) {
    console.log(url)
    const result = window.confirm(`Sicher das du das Backup vom ${url} mit der aktuellen Liste überschreiben willst?`);
    if (result) {
      const  resp  = await postData(`${CONFIG.BACKEND_URL}/admin/restore`, {input: url});
      console.log(resp);
      if(resp.success){
        alert("Backup erfolgreich eingespielt!")
      }else{
      alert("FEHLER! Backup konnte nicht eingespielt werden!")
      }
    }
  }
  async function fetchData(url) {
    const token = localStorage.getItem('deltatoken');
    console.log(token)
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    if(response.status === 401){
        window.location.href = '/login';
    }
    const data = await response.json();
    return data;    
  }
  async function fetchFaesser() {
    const  profiles  = await fetchData(`${CONFIG.BACKEND_URL}/admin/backups`);
    console.log(profiles)
    setFaesser(profiles.backups);
  }
  
  async function generateBackup() {
    const  profiles  = await fetchData(`${CONFIG.BACKEND_URL}/admin/generate_backup`);
    console.log(profiles)
    setFaesser(profiles.backups);
  }
  
  function downloadJson(data, filename) {
    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'data.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}

  const downloadBackupRaw = async (Refurl) => {  
    setDownloading(true)
    const data = await postData(`${CONFIG.BACKEND_URL}/admin/restore_download`, {input: Refurl});
   
    const backupDate = data.date;
    try {
        const data = await postData(`${CONFIG.BACKEND_URL}/admin/restore_download`, { input: Refurl });
        
        downloadJson(data, `backup_${backupDate}.json`);
    } catch (error) {
        console.error('Error:', error);
    }

  
    setDownloading(false)
  
  };



  const downloadBackup = async (Refurl) => {  
    setDownloading(true)
    const data = await postData(`${CONFIG.BACKEND_URL}/admin/restore_download`, {input: Refurl});
    
      if (data.notMatched.length > 0)
        alert(`ERROR! Folgende Corpsbrüder wurden nicht gemacht: ${data.notMatched}`);
     
      const fileUrl = `${CONFIG.BACKEND_URL}/kassenexl/${data.path}`;
      const backupDate = data.date;
      fetch(fileUrl)
        .then(response => response.blob())
        .then(blob => {
          const filename = `backup_${backupDate}.xlsx`;
          // Create a downloadable link
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch(error => console.error('Error downloading file:', error));
    
    setDownloading(false)
  
  };

  
  useEffect(() => {  
    
    fetchFaesser();
  }, []);

  
  return (
    <>
      <Helmet>
        <title> Kasse </title>
      </Helmet>
    <div style={{width: "300px", position: "fixed", background: "#ebeef1", height: "100%", bottom: 0, top: 0, left: 0, zIndex: 300}}>
      <Box sx={{ mb: 5, mx: 2.5, marginTop: "20px" }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={"a"} alt={"Kasse"}/>

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                Kasse
              </Typography>

              
            </Box>
          </StyledAccount>
        </Link>
      </Box>
      <NavSection data={navKasse} />
</div>
      <Container style={{marginLeft: "300px"}}>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center', marginLeft: "0px" }}>

          <Typography variant="h3" paragraph >
           Delta Drinks Kasse
          </Typography>
          <Button variant="contained" onClick={() => generateBackup()}  style={{height: "50px", width: "200px"}}>Generate Backup</Button>
          <Typography style={{marginTop: "10px"}} >
           Backups werden automatisch alle 12h und vor jedem Löschen erstellt
          </Typography>
          <div style={{ display: (downloading ?  "flex" : "none"), marginTop: "20px"}}>
            <CircularProgress  style={{color: '#000'}}/>
            </div>
          <div style={{ marginTop: "10px",paddingBottom: "30px", marginLeft: "3px", marginRigth: "10px",  width: '100%',overflowX: "auto"}}>
          <table style={{ padding: '25px',  marginTop: '5px', borderCollapse: "collapse"}}>
          <thead>
              <tr>
                <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>Datum</td>
                <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>Restore</td>
                <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>Download Excel</td>
                <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>Download Raw</td>
                
              </tr>
            </thead>
                <tbody>
                {faesser.map((item, index) => (
                  <tr key={index}>
                      <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>{item}</td>
                      <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}><Button variant="contained" onClick={() => restore(item)}>Restore</Button></td>
                      <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}><Button variant="contained" style={{background: '#000'}} onClick={() => downloadBackup(item)}>Download</Button></td>
                      <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}><Button variant="contained" style={{background: '#000'}} onClick={() => downloadBackupRaw(item)}>Download Raw</Button></td>
                  </tr>
                ))}

                </tbody>

              </table>
          </div>
          
        </StyledContent> 
      </Container>
    </>
  );
}
